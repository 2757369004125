import React from 'react';

const ExternalLink = ({id, classNames, href, label, role, children}) => {
    return (
        <a id={id} className={classNames} target="_blank" rel="noopener noreferrer nofollow" href={href} aria-label={label} role={role}>
            {children}
        </a>
    );
};

export default ExternalLink;