import React from 'react';
import Helmet from "react-helmet";
import {graphql, useStaticQuery} from "gatsby";

const SEO = () => {

    const {site, logoImage} = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    siteUrl
                    title
                    description
                    author
                }
            },
            logoImage: file(relativePath: {eq: "images/website-icon.png"}) {
                publicURL
            }
        }
    `);

    return (
        <Helmet
            title={site.siteMetadata.title}
            meta={[
                {
                    name: "description",
                    content: `${site.siteMetadata.description}`
                },
                {
                    name: "keywords",
                    content: "web development, programowanie, tworzenie stron internetowych, aplikacje webowe"
                }
            ]}
            script={[
                {
                    crossorigin: "anonymous",
                    src: "https://kit.fontawesome.com/a6339a54fc.js"
                },
                {
                    type: 'application/ld+json', innerHTML: `{
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "url": "${site.siteMetadata.siteUrl}",
                            "name": "${site.siteMetadata.title}",
                            "logo": "${site.siteMetadata.siteUrl}${logoImage.publicURL}"
                        }`
                }
            ]}
        >
            <html lang={"pl"}/>
        </Helmet>
    );
};

export default SEO;