import React from "react";
import PropTypes from "prop-types";
import {Link, useI18next} from "gatsby-plugin-react-i18next";
import ExternalLink from "./ExternalLink";
import isPolishVersion from "../helpers/language";

const Menu = props => {

    const {languages, originalPath, t, language} = useI18next();
    const isPolishLang = isPolishVersion(language);

    return (
        <nav id="menu">
            <div className="inner">
                <ul className="links">
                    <li>
                        <Link onClick={props.onToggleMenu} to="/">
                            {t('menu.home')}
                        </Link>
                    </li>
                    <li>
                        <Link onClick={props.onToggleMenu} to="/static_sites">
                            {t('menu.websites')}
                        </Link>
                    </li>
                    {isPolishLang && <li>
                        <Link onClick={props.onToggleMenu} to="/mentoring">
                            {t('menu.mentoring')}
                        </Link>
                    </li>}
                    <li>
                        <Link onClick={props.onToggleMenu} to="/#about_me">
                            {t('menu.about')}
                        </Link>
                    </li>
                    <li>
                        <Link onClick={props.onToggleMenu} to="/#contact">
                            {t('menu.contact')}
                        </Link>
                    </li>
                </ul>
                <ul className="actions vertical">
                    <li>
                        <ExternalLink href={"https://www.linkedin.com/in/b-plaza"} classNames={"button fit"}>
                            Linked In
                        </ExternalLink>
                    </li>
                    <li>
                        <ExternalLink href={"https://github.com/BartPlaza"} classNames={"button fit"}>
                            GitHub
                        </ExternalLink>
                    </li>
                </ul>
            </div>
            <button className="close" onClick={props.onToggleMenu}>
                Close
            </button>
            <div className="languages">
                {languages.map((lng, count) => (
                    <>
                        {count > 0 && <span>|</span>}
                        <Link key={lng} to={originalPath} language={lng}>
                            {lng}
                        </Link>
                    </>
                ))}
            </div>
        </nav>
    )
};

Menu.propTypes = {
    onToggleMenu: PropTypes.func
};

export default Menu;
