import React from "react";
import foto from '../assets/images/foto.jpg';
import ExternalLink from "./ExternalLink";

const Footer = props => (
    <footer id="footer">
        <svg className={'footer__bridge'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 265">
            <path fillOpacity="1" d="M0,224L60,213.3C120,203,240,181,360,154.7C480,128,600,96,720,106.7C840,117,960,171,1080,176C1200,181,1320,139,1380,117.3L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"/>
        </svg>
        <div className="inner footer__wrapper">
            <div className="footer__content">
                <div>
                    {/*<img className="foto" src={foto} alt="Clever It CEO"/>*/}
                </div>
                <div className="footer__content__items">
                    <ul className="copyright">
                        <li>&copy; Clever IT - Bartłomiej Płaza {new Date().getFullYear()}</li>
                    </ul>
                    <ul className="icons">
                        <li>
                            <ExternalLink href={"https://github.com/BartPlaza"} classNames={"icon alt fa-github"}
                                          label={"GitHub"}/>
                        </li>
                        <li>
                            <ExternalLink href={"https://www.linkedin.com/in/b-plaza"} classNames={"icon alt fa-linkedin"}
                                          label={"LinkedIn"}/>
                        </li>
                        <li>
                            <ExternalLink href={"mailto:bartlomiejplaza@wp.pl"} classNames={"icon alt fa-envelope"}
                                          label={"Email"}/>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
